import { useBottomModalSheet } from "@/components/BottomModalSheet";
import { useFlow } from "@/domains/questionAnswering";
import { useAccount } from "@/hooks";
import {
  AccountGroup,
  AccountGroupCreationPayloadMap,
  ConfidentialAccount,
  RegisterPayload,
} from "@/types/coreApiMirror";
import {
  DreamcatcherCubeProps,
  DreamcatcherFlowProps,
  DreamcatcherValue,
  useAuthStatus,
  usePosthog,
} from "@pairtreefamily/ui";
import { Result } from "@pairtreefamily/utils";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { PostAccountCreationActions } from "../postAccountCreation";

export type OnboardingPage = "select-account" | "flow" | "sign-up" | "log-in";

// Each account group implements an OnboardingHook.
// This is added to the accountGroupOnboarding map in useOnboarding.
export interface OnboardingHook<T, G extends AccountGroup> {
  // Is onboarding enabled for this account group? Hides the option
  // to create an account for this group if false.
  onboardingEnabled: boolean;
  onboardingOption: Pick<DreamcatcherCubeProps, "text" | "icon" | "bgColor"> &
    Partial<Pick<DreamcatcherCubeProps, "data-test">>;
  // This is the account group specific payload that is used to create the account.
  // It's a Result so we can return an error if the payload is not complete.
  registerPayload: Result<AccountGroupCreationPayloadMap[G], string>;
  // This is the payload that is sent to the server to create the account.
  // Its the responsibility of the implementor to put the partial registerPayload above in the proper key
  // of the RegisterPayload sent to the server.
  createCompleteRegisterPayload: (token: string) => RegisterPayload;
  // This indicates which products should be selectable for this account group.
  accountGroup: G;
  creationParameters: T;
  // Returns a component that is rendered at Sign up.
  // The component should display a way to fill out all fields
  // necessary to fill out the required fields for this
  // account group.
  renderSignUpComponent: () => JSX.Element;
  // Dreamcatcher flow for onboarding this account group.
  dreamcatcherFlowId: string;
}

export type AccountGroupOnboardingMap = {
  [G in AccountGroup]?: OnboardingHook<unknown, G>;
};

export interface UseOnboardingProps {
  initialPage?: OnboardingPage;
  accountGroup?: AccountGroup;
  accountGroupOnboarding: AccountGroupOnboardingMap;
  postAccountCreationActions: PostAccountCreationActions;
  navigateToLauncher: () => void;
}

export const useOnboarding = (props: UseOnboardingProps) => {
  const router = useRouter();
  const authStatus = useAuthStatus();
  const { account, isLoading: isAccountLoading, createAccount } = useAccount();
  const modal = useBottomModalSheet();
  const posthog = usePosthog();

  const accountGroupOnboarding: AccountGroupOnboardingMap =
    props.accountGroupOnboarding;

  const postAccountCreationActions = props.postAccountCreationActions;
  const navigateToLauncher = props.navigateToLauncher;

  const [accountGroup, setAccountGroup] = useState<AccountGroup | null>(
    props?.accountGroup ?? null
  );
  const initialPage = props?.initialPage ?? "select-account";
  const [currentPage, setCurrentPage] = useState<OnboardingPage>(initialPage);
  const [signUpError, setSignUpError] = useState<string | null>(null);
  const [createAccountIsLoading, setCreateAccountIsLoading] = useState(false);

  const [onboardingValues, setOnboardingValues] = useState<
    DreamcatcherValue[] | null
  >(null);
  const [onboardingPageStack, setOnboardingPageStack] = useState<
    string[] | null
  >(null);
  const [lastOnboardingPage, _setLastOnboardingPage] = useState<string | null>(
    null
  );
  const setLastOnboardingPage = (page: string | null) => {
    _setLastOnboardingPage(page);
    posthog.capture(`dreamcatcher-onboarding-flow-step-visit`, {
      page,
    });
  };

  const dreamcatcherFlowId = useMemo(() => {
    if (accountGroup) {
      return accountGroupOnboarding[accountGroup]?.dreamcatcherFlowId;
    } else {
      console.error(
        `No dreamcatcher flow found for account group ${accountGroup}`
      );
      return undefined;
    }
  }, [accountGroup, accountGroupOnboarding]);
  const flowHook = useFlow();

  const dreamcatcherFlow: DreamcatcherFlowProps | "loading" | "not_found" =
    useMemo(() => {
      if (flowHook.flowIsLoading) {
        return "loading";
      }
      if (flowHook.flowError) {
        console.error(`Error loading dreamcatcher flow: ${flowHook.flowError}`);
        return "not_found";
      }
      return flowHook.flow ?? "not_found";
    }, [flowHook]);

  // Clear page history when the dreamcatcher flow changes.
  // and fetch a new dreamcatcher flow when the account group changes.
  useEffect(() => {
    setOnboardingPageStack(null);
    setLastOnboardingPage(null);
    setOnboardingValues(null);
    if (dreamcatcherFlowId) {
      flowHook.setFlowId(dreamcatcherFlowId);
    }
  }, [dreamcatcherFlowId, flowHook.setFlowId]);

  useEffect(() => {
    if (account) {
      setAccountGroup(account.group);
    } else {
      setCurrentPage(initialPage);
    }
  }, [account]);

  // If we're already logged into an account, skip to launcher
  // and open the modal.
  // Only check the first time the account has loaded.
  const isFirstAccountLoad = useRef(true);
  useEffect(() => {
    if (isAccountLoading) {
      return;
    }

    if (isFirstAccountLoad.current && account) {
      isFirstAccountLoad.current = false;
      navigateToLauncher();
    }
  }, [isAccountLoading, account, modal]);

  // Send an analytics event for when the current page is shown.
  useEffect(() => {
    if (modal.state == "expanded") {
      posthog.capture(`dreamcatcher-onboarding-page-view-${currentPage}`);
    }
  }, [modal.state, currentPage, posthog.capture]);

  const externalNavigation = (url: string) => {
    router.push(url);
  };

  const nextPage = () => {
    if (currentPage === "select-account") {
      setCurrentPage("flow");
    }
    if (currentPage === "flow") {
      setCurrentPage("sign-up");
    }
    if (currentPage === "sign-up") {
      navigateToLauncher();
    }
  };

  return {
    accountGroup,
    currentPage,
    setCurrentPage,
    signUpError,
    createAccountIsLoading,
    createAccount: async (
      token: string,
      needsAccount: boolean
    ): Promise<void> => {
      setCreateAccountIsLoading(true);
      const successActions = (account?: ConfidentialAccount) => {
        setCreateAccountIsLoading(false);
        setSignUpError(null);
        if (account) {
          posthog.capture(`account_created`, { group: account.group });
          if (
            dreamcatcherFlow !== "not_found" &&
            dreamcatcherFlow !== "loading" &&
            onboardingValues
          ) {
            flowHook.submitFlow({
              values: onboardingValues,
            });
          }
          postAccountCreationActions(
            account.product_interest ?? [],
            modal.setState,
            modal.setPopupMessage,
            externalNavigation,
            account
          );
        }
        nextPage();
      };
      if (needsAccount == false) {
        successActions();
        return;
      }
      let registerTokenPayload: RegisterPayload;
      if (!accountGroup) {
        throw new Error("Must select account group before creating account");
      }
      // Check if accountGroup is in accountGroupOnboarding
      const accountOnboarding = accountGroupOnboarding[accountGroup];
      if (!accountOnboarding) {
        throw new Error("Not implemented");
      }

      if (!accountOnboarding.registerPayload.ok) {
        setSignUpError(accountOnboarding.registerPayload.error);
        return;
      }

      registerTokenPayload =
        accountOnboarding.createCompleteRegisterPayload(token);

      const result = await createAccount(registerTokenPayload);
      if (!result.ok) {
        setSignUpError(`Got error when creating account: ${result.error}`);
        return;
      }

      return successActions(result.content);
    },
    setAccountGroup,
    accountGroupOnboarding,
    nextPage,
    previousPage: () => {
      if (currentPage === "flow") {
        setCurrentPage("select-account");
      }
      if (currentPage === "sign-up") {
        setCurrentPage("flow");
      }
      if (currentPage === "log-in") {
        setCurrentPage("select-account");
      }
    },
    externalNavigation,
    signOut: () => {
      authStatus.signOut();
      setCurrentPage(initialPage);
    },
    modal,
    onboardingValues,
    setOnboardingValues,
    onboardingPageStack,
    setOnboardingPageStack,
    lastOnboardingPage,
    setLastOnboardingPage,
    dreamcatcherFlow,
    navigateToLauncher,
  };
};

export type OnboardingPageProps = ReturnType<typeof useOnboarding>;
