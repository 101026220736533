import { CreateProfessionalPayload } from "@/types/coreApiMirror";
import { PROIcon, TextInput, usePosthog } from "@pairtreefamily/ui";
import { Result } from "@pairtreefamily/utils";
import { useState } from "react";
import { OnboardingHook } from "..";

export const useProOnboarding = () => {
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);
  const { enabledFeatureFlags } = usePosthog();

  const registerPayload: Result<CreateProfessionalPayload, string> =
    postalCode == null
      ? { ok: false, error: "Postal Code must be filled out" }
      : firstName == null
      ? { ok: false, error: "First name must be filled out" }
      : {
          ok: true,
          content: {
            postal_code: postalCode,
            first_name: firstName,
            last_name: lastName ?? undefined,
          },
        };

  const creationParameters = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    postalCode,
    setPostalCode,
  };
  const onboardingProperties: OnboardingHook<typeof creationParameters, "pro"> =
    {
      onboardingEnabled: enabledFeatureFlags["dreamcatcher-pro-signup"],
      onboardingOption: {
        text: "Adoption Professional",
        icon: <PROIcon width="33" height="30" />,
      },
      registerPayload,
      createCompleteRegisterPayload: (token) => {
        if (registerPayload.ok) {
          return {
            token,
            account_group: "pro",
            professional: registerPayload.content,
          };
        }
        throw new Error("registerPayload must be ok");
      },
      accountGroup: "pro",
      dreamcatcherFlowId: "pro_onboarding",
      creationParameters,
      renderSignUpComponent: () => (
        <ProSignUpDetails creationParameters={creationParameters} />
      ),
    };

  return onboardingProperties;
};

type ProCreationParameters = ReturnType<
  typeof useProOnboarding
>["creationParameters"];

export const ProSignUpDetails = (props: {
  creationParameters: ProCreationParameters;
}) => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    postalCode,
    setPostalCode,
  } = props.creationParameters;

  return (
    <>
      <p>
        Okay, let&apos;s get you signed up and I&apos;ll redirect you to where
        you need to go!
      </p>
      <TextInput
        label="First Name"
        data-test="first-name"
        value={firstName ?? ""}
        onChange={(e) => {
          setFirstName(e);
        }}
      />
      <TextInput
        label="Last Name"
        data-test="last-name"
        value={lastName ?? ""}
        onChange={(e) => {
          setLastName(e);
        }}
      />
      <TextInput
        label="Postal Code where you operate from"
        data-test="postal-code"
        value={postalCode ?? ""}
        onChange={(e) => {
          setPostalCode(e);
        }}
      />
    </>
  );
};
