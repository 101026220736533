import { DreamcatcherCubeGroup } from "@pairtreefamily/ui";
import type { OnboardingPageProps } from "../hooks/useOnboarding";

export const SelectAccount = (pageProps: OnboardingPageProps) => {
  const { setAccountGroup, nextPage, setCurrentPage, accountGroupOnboarding } =
    pageProps;

  let onboardingPathSelections = Object.values(accountGroupOnboarding)
    .filter((o) => o.onboardingEnabled)
    .map(
      ({
        onboardingOption: { text, icon, bgColor, ...rest },
        accountGroup,
      }) => ({
        text,
        icon,
        bgColor,
        ...rest,
        action: { type: "noop" as "noop" },
        actionCallback: () => {
          setAccountGroup(accountGroup);
          nextPage();
        },
      })
    );

  return (
    <div className="text-body-1 flex flex-col">
      <h1 className="text-head-3 pb-4 text-center">
        <em>You&apos;re in the right place!</em>
      </h1>
      <div className="mx-auto mb-12 px-4">
        Choose an experience that best suits your description or
        <a
          className="pl-1 font-bold text-teal"
          data-test="log-in"
          role="button"
          onClick={() => setCurrentPage("log-in")}
        >
          Login
        </a>
      </div>
      <DreamcatcherCubeGroup launcherActions={onboardingPathSelections} />
    </div>
  );
};
