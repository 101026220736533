import { app } from "@/utils/firebaseConfig";
import {
  Button,
  Loading,
  LoginAndSignUp,
  useAuthStatus,
} from "@pairtreefamily/ui";
import { OnboardingPageProps } from "../hooks/useOnboarding";

// Separating onSuccess logic into hook so we can easily test it with cypress.
export const useLogInPage = (pageProps: OnboardingPageProps) => {
  const { setCurrentPage, navigateToLauncher } = pageProps;
  const authStatus = useAuthStatus();

  return {
    onSuccess: (token: string, needsAccount: boolean) => {
      if (needsAccount) {
        // why are you here, get outta here!
        // It's possible a user attempts to go here first, log in with a google account that
        // we don't have an account for, and then we need to create an account for them.
        authStatus.signOut();
        setCurrentPage("select-account");
      } else {
        // Otherwise, welcome back. Get you to the launcher.
        navigateToLauncher();
      }
    },
  };
};

export const LogInPage = (pageProps: OnboardingPageProps) => {
  const { signUpError, createAccountIsLoading, previousPage, setCurrentPage } =
    pageProps;
  const { onSuccess } = useLogInPage(pageProps);

  if (createAccountIsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <LoginAndSignUp
        disabled={false}
        app={app}
        mode="login"
        onSuccess={onSuccess}
      />
      <Button
        data-test="select-signup-previous"
        otherStyles="row-span-2"
        onClick={() => previousPage()}
        style="secondary"
        color="teal"
      >
        Previous
      </Button>
      {/* TODO better positioning  with the button and sign up */}

      <div className="text-body-1 text-red" data-test="sign-up-error">
        {signUpError ? `Error: ${signUpError}` : ""}
      </div>
    </div>
  );
};
