import { app } from "@/utils/firebaseConfig";
import { GoBackButton, Loading, LoginAndSignUp } from "@pairtreefamily/ui";
import { useMemo } from "react";
import { OnboardingPageProps } from "../hooks/useOnboarding";

export const SignUpPage = (pageProps: OnboardingPageProps) => {
  const {
    accountGroup,
    signUpError,
    createAccount,
    createAccountIsLoading,
    accountGroupOnboarding,
    previousPage,
  } = pageProps;

  if (!accountGroup) {
    throw new Error(
      "Account group not set. Was the 'will navigate to select-account from select-product-interest if no account group is set' test modified?"
    );
  }
  const accountOnboarding = useMemo(
    () => accountGroupOnboarding[accountGroup],
    [accountGroup, accountGroupOnboarding]
  );
  if (!accountOnboarding) {
    throw new Error(
      "Could not find account onboarding for this account group."
    );
  }

  if (createAccountIsLoading) {
    return <Loading />;
  }

  return (
    <div>
      <GoBackButton
        data-test="select-signup-previous"
        otherStyles="row-span-2"
        onClick={() => previousPage()}
      />
      {accountOnboarding.renderSignUpComponent()}

      <LoginAndSignUp
        disabled={!accountOnboarding?.registerPayload.ok}
        app={app}
        mode="signup"
        onSuccess={createAccount}
      />
      {/* TODO better positioning  with the button and sign up */}
      <p className="text-body-4 mt-8 text-center">
        By creating an account, you are agreeing to our{" "}
        <a
          className="text-teal"
          href="https://pairtreefamily.com/terms-and-conditions"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{"  "}
        <a
          className="text-teal"
          href="https://pairtreefamily.com/privacyPolicy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </p>

      <div className="text-body-1 text-red" data-test="sign-up-error">
        {signUpError ? `Error: ${signUpError}` : ""}
      </div>
    </div>
  );
};
