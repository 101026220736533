import { CreateAdoptiveParentPayload } from "@/types/coreApiMirror";
import { TextInput } from "@pairtreefamily/ui";
import { FAMIcon } from "@pairtreefamily/ui";
import { Result } from "@pairtreefamily/utils";
import { useState } from "react";
import { OnboardingHook } from "..";

export const useFamOnboarding = () => {
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);

  const registerPayload: Result<CreateAdoptiveParentPayload, string> =
    postalCode == null
      ? { ok: false, error: "Postal Code must be filled out" }
      : firstName == null
      ? { ok: false, error: "First name must be filled out" }
      : lastName == null
      ? { ok: false, error: "Last name must be filled out" }
      : {
          ok: true,
          content: {
            postal_code: postalCode,
            first_name: firstName,
            last_name: lastName,
          },
        };

  const creationParameters = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    postalCode,
    setPostalCode,
  };

  const onboardingProperties: OnboardingHook<typeof creationParameters, "fam"> =
    {
      onboardingEnabled: true,
      onboardingOption: {
        text: "Adoptive Family",
        icon: <FAMIcon />,
        bgColor: "bg-teal",
      },
      registerPayload,
      createCompleteRegisterPayload: (token) => {
        if (registerPayload.ok) {
          return {
            token,
            account_group: "fam",
            adoptive_parent: registerPayload.content,
          };
        }
        throw new Error("registerPayload must be ok");
      },
      accountGroup: "fam",
      creationParameters,
      renderSignUpComponent: () => (
        <FamSignUpDetails creationParameters={creationParameters} />
      ),
      dreamcatcherFlowId: "fam_onboarding",
    };

  return onboardingProperties;
};

type FamCreationParameters = ReturnType<
  typeof useFamOnboarding
>["creationParameters"];

export const FamSignUpDetails = (props: {
  creationParameters: FamCreationParameters;
}) => {
  const {
    setFirstName,
    firstName,
    setLastName,
    lastName,
    setPostalCode,
    postalCode,
  } = props.creationParameters;

  return (
    <>
      <p>
        Okay, let&apos;s get you signed up and I&apos;ll redirect you to where
        you need to go!
      </p>
      <TextInput
        label="First Name"
        data-test="first-name"
        value={firstName ?? ""}
        onChange={(e) => {
          setFirstName(e);
        }}
      />
      <TextInput
        label="Last Name"
        data-test="last-name"
        value={lastName ?? ""}
        onChange={(e) => {
          setLastName(e);
        }}
      />
      <TextInput
        label="Postal Code"
        data-test="postal-code"
        value={postalCode ?? ""}
        onChange={(e) => {
          setPostalCode(e);
        }}
      />
    </>
  );
};
