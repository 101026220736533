import { EMIcon, Select, TextInput } from "@pairtreefamily/ui";
import { Result } from "@pairtreefamily/utils";
import { useState } from "react";
import {
  CreateBiologicalParentPayload,
  State,
  stateDisplayNames,
  states,
} from "@/types/coreApiMirror";
import type { OnboardingHook } from "./useOnboarding";

export const useBioOnboarding = () => {
  const [firstName, setFirstName] = useState<string | null>(null);
  const [state, setState] = useState<State | null>(null);

  const registerPayload: Result<CreateBiologicalParentPayload, string> =
    state == null
      ? { ok: false, error: "State must be selected" }
      : firstName == null
      ? { ok: false, error: "First name must be filled out" }
      : {
          ok: true,
          content: { state, first_name: firstName },
        };

  const creationParameters = {
    firstName,
    setFirstName,
    state,
    setState,
  };

  const onboardingProperties: OnboardingHook<typeof creationParameters, "bio"> =
    {
      onboardingEnabled: true,
      onboardingOption: {
        text: "Expectant Mom",
        icon: <EMIcon />,
        bgColor: "bg-green",
      },
      registerPayload,
      createCompleteRegisterPayload: (token) => {
        if (registerPayload.ok) {
          return {
            token,
            account_group: "bio",
            biological_parent: registerPayload.content,
          };
        }
        throw new Error("registerPayload must be ok");
      },
      accountGroup: "bio",
      dreamcatcherFlowId: "bio_onboarding",
      creationParameters,
      renderSignUpComponent: () => (
        <BioSignUpDetails creationParameters={creationParameters} />
      ),
    };
  return onboardingProperties;
};

type BioCreationParameters = ReturnType<
  typeof useBioOnboarding
>["creationParameters"];

export const BioSignUpDetails = (props: {
  creationParameters: BioCreationParameters;
}) => {
  const { firstName, setFirstName, state, setState } = props.creationParameters;

  return (
    <>
      <p>
        Okay, let&apos;s get you signed up and I&apos;ll redirect you to where
        you need to go!
      </p>
      <TextInput
        label="First Name"
        data-test="first-name"
        value={firstName ?? ""}
        onChange={(e) => {
          setFirstName(e);
        }}
      />
      <Select
        options={states.map((state: State) => ({
          id: state,
          label: stateDisplayNames[state],
        }))}
        value={state}
        data-test="state"
        label="State"
        placeholder="State you live in"
        onChange={(e) => setState(e)}
      />
    </>
  );
};
