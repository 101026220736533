import {
  ConfidentialAccount,
  ProductInterest,
  State,
  stateDisplayNames,
} from "@/types/coreApiMirror";

export type PostAccountCreationActions = typeof postAccountCreationActions;

// Actions to perform after an account is created.
export const postAccountCreationActions = (
  productInterest: ProductInterest[],
  setModalState: (state: "expanded" | "collapsed") => void,
  setModalPopupMessage: (message: string) => void,
  externalNavigation: (url: string) => void,
  account: ConfidentialAccount
) => {
  // TODO Do we want to do anything here or simply redirect to the launcher, which happens automatically?
  // The previous v1 code is preserved below and can be enabled via uncommenting the line below.
  // dreamcatcherV1PostAccountCreationActions(
  //   productInterest,
  //   setModalState,
  //   setModalPopupMessage,
  //   externalNavigation,
  //   account
  // );
  return;
};

export const dreamcatcherV1PostAccountCreationActions = (
  productInterest: ProductInterest[],
  setModalState: (state: "expanded" | "collapsed") => void,
  setModalPopupMessage: (message: string) => void,
  externalNavigation: (url: string) => void,
  account: ConfidentialAccount
) => {
  if (account.group == "fam") {
    // If the product interest indicated interest in home study or complete, send them to
    // the home study interest form.
    if (
      productInterest.indexOf("home_study") > -1 ||
      productInterest.indexOf("complete") > -1
    ) {
      // TODO Add home study knowledge base redirect
      externalNavigation(
        `https://pairtreefamily.com/services/homeStudy${
          account.profile.state ? `?state=${account.profile.state}` : ""
        }#providers`
      );
      setModalState("collapsed");
      setModalPopupMessage("I've navigated you to home study!");
      return;
    }
  }

  if (account.group == "bio") {
    const interestedInResources = productInterest.indexOf("resources") > -1;
    const interestedInConnect = productInterest.indexOf("connect") > -1;
    if (interestedInConnect && interestedInResources) {
      // Do nothing. Just show launcher.
      return;
    }
    if (interestedInResources) {
      externalNavigation(
        `https://pairtreefamily.com/resources${
          account.profile.state ? `?state=${account.profile.state}` : ""
        }`
      );
      setModalState("collapsed");
      setModalPopupMessage(
        "I've navigated you to resources and filtered to your state!"
      );
      return;
    }
    if (interestedInConnect) {
      externalNavigation(
        `https://pairtreefamily.com/families${
          account.profile.state
            ? `?state=${stateDisplayNames[account.profile.state as State]}`
            : ""
        }`
      );
      setModalState("collapsed");
      setModalPopupMessage(
        "I've navigated you to waiting and filtered to your state! You can also filter by other criteria."
      );
      return;
    }
  }
};
