import {
  DreamcatcherFlow,
  DreamcatcherPathProvider,
  DreamcatcherValue,
  GoBackButton,
  Loading,
} from "@pairtreefamily/ui";
import { useOnboarding } from "..";
import { LogInPage } from "./LogIn";
import { SelectAccount } from "./SelectAccount";
import { SignUpPage } from "./SignUp";

export const OnboardingContainer = (
  onboardingPageProps: ReturnType<typeof useOnboarding>
) => {
  if (onboardingPageProps.currentPage === "select-account") {
    return (
      <div>
        <SelectAccount {...onboardingPageProps} />
      </div>
    );
  }

  // Sign up
  if (onboardingPageProps.currentPage === "sign-up") {
    return (
      <div>
        <SignUpPage {...onboardingPageProps} />
      </div>
    );
  }

  // Log in
  if (onboardingPageProps.currentPage === "log-in") {
    return (
      <div>
        <LogInPage {...onboardingPageProps} />
      </div>
    );
  }

  // If any of the *-flow pages are active, show the flow.
  if (onboardingPageProps.currentPage == "flow") {
    const flow = onboardingPageProps.dreamcatcherFlow;
    if (flow == "loading") {
      return <Loading />;
    }
    if (flow == "not_found") {
      return <div>Not found</div>;
    }
    const completedFlow = (values: DreamcatcherValue[]) => {
      onboardingPageProps.nextPage();
    };
    return (
      <DreamcatcherPathProvider
        values={onboardingPageProps.onboardingValues ?? []}
        setValues={onboardingPageProps.setOnboardingValues}
      >
        <div className="flex flex-col items-start">
          <DreamcatcherFlow
            {...flow}
            onComplete={completedFlow}
            onCurrentStepChanged={onboardingPageProps.setLastOnboardingPage}
            onPreviousStepStackChanged={
              onboardingPageProps.setOnboardingPageStack
            }
            startingStep={onboardingPageProps.lastOnboardingPage ?? undefined}
            previousStepStack={
              onboardingPageProps.onboardingPageStack ?? undefined
            }
            backButtonFallbackBehavior={() =>
              onboardingPageProps.previousPage()
            }
          />
        </div>
      </DreamcatcherPathProvider>
    );
  }

  return <div></div>;
};
