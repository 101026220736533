import {
  OnboardingContainer,
  postAccountCreationActions,
  useOnboarding,
  useFamOnboarding,
  useProOnboarding,
  useBioOnboarding,
} from "@/domains/onboarding";
import { useRouter } from "next/router";

const Index = () => {
  const router = useRouter();
  // Register account group's onboarding hooks
  const accountGroupOnboarding = {
    bio: useBioOnboarding(),
    pro: useProOnboarding(),
    fam: useFamOnboarding(),
  };

  const onboardingPageProps = useOnboarding({
    accountGroupOnboarding,
    postAccountCreationActions,
    navigateToLauncher: () => {
      router.push("/launcher");
    },
  });

  return (
    <div className="flex h-screen flex-col items-center">
      <div className="flex grow flex-col justify-center">
        <OnboardingContainer {...onboardingPageProps} />
      </div>
    </div>
  );
};

export default Index;
